<template>
  <div class="container-fluid annotator">
     <LoaderComponent v-if="loading" />

  </div>
</template>
<script type="text/javascript">
import { MatriculasannotatorMixin } from "./Matriculasannotator.mixin.js";
import Barchart from "../../components/ui/Barchart/Barchart";
import DateRangePicker from "vue2-daterange-picker";
import LoaderComponent from "../../components/ui/loader/loader";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {};
  },
  watch: {},
  props: {},
  components: {
    LoaderComponent
  },
  name: "Matriculasannotator",
  mixins: [MatriculasannotatorMixin],
};
</script>
<style src="./Matriculasannotator.less" lang="less" />
