<template>
    <div>
        <div class="overlay">
              <div class="lds-dual-ring"></div>
        </div>
       
    </div>
    
</template>

<script type="text/javascript">
export default {
    props:{
        
    },
    name: 'Loader',
    data(){
        return {
              
        }
    },
    computed:{
      
    },
    mounted() {

    },
    methods:{
      
    }
   
}
</script>
<style src="./loader.less" lang="less" />