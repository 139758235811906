import moment from "moment";
import { formatTime } from "../../helpers/helpers";
import { getMatriculasannotator } from '../../services/logs.services'

export const MatriculasannotatorMixin = {
    data() {
        return {
            loading: false,
            logs: [],
            query: {
                logGroupName: 'admin_matriculas',
                logStreamName: 'admin_matriculas_anotador',
                limit: 10000
            },
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
        };
    },
    created() {},
    mounted() {
        moment.locale('pt-br')
        this.getAnnotations()
    },
    filters: {},
    watch: {},
    computed: {

    },
    methods: {
        async getAnnotations() {
            this.loading = true
            try {
                this.query.startTime = moment().format('L')
                this.query.endTime = moment().format('L')
                const { data } = await getMatriculasannotator(this.query)
                console.log(data.data)
                this.loading = false


            } catch (e) {
                this.loading = false
                this.$tokentest(e.response.status)
                console.log(e)
            }
        },
        treatData(logs) {
            logs - logs.sort((a, b) => { return b.timestamp - a.timestamp })
            logs = logs.filter(val => {
                for (let i in val) {
                    if (i == 'eventId' || i == 'ingestionTime' || i == 'logStreamName') {
                        delete val[i]
                    }

                }
                val['data'] = moment(new Date(val.timestamp)).format('MMMM Do YYYY, h:mm:ss a');
                return val
            })

            return logs
        },


    },
};